<template>
    <component :is="isLoading || isErrorOccured ? 'div' : 'b-card'">
        <!-- Spinner -->
        <div v-if="isLoading" class="centered">
            <b-spinner variant="primary" label="Text Centered" />
        </div>

        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="isErrorOccured">
            <h4 class="alert-heading">
                {{ $t('user.fetchingError') }}
            </h4>

            <i18n tag="div" class="alert-body" path="user.checkList">
                <template v-slot:action>
                    <b-link class="alert-link" :to="{ name: 'users-list' }">
                        {{ $t('user.list') }}
                    </b-link>
                </template>
            </i18n>
        </b-alert>

        <b-tabs v-if="isUserDataFetched" pills>
            <!-- Tab: Account -->
            <b-tab active>
                <template #title>
                    <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">
                        {{ $t('user.account') }}
                    </span>
                </template>

                <user-edit-tab-account
                    :user-data="userData"
                    class="mt-2 pt-75"
                    @reset="handleReset"
                />
            </b-tab>

            <!-- Tab: Information -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />

                    <span class="d-none d-sm-inline">
                        {{ $t('user.information') }}
                    </span>
                </template>
                <user-edit-tab-information class="mt-2 pt-75" />
            </b-tab>

            <!-- Tab: Social -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="Share2Icon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />

                    <span class="d-none d-sm-inline">
                        {{ $t('user.social') }}
                    </span>
                </template>
                <user-edit-tab-social class="mt-2 pt-75" />
            </b-tab>
        </b-tabs>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BSpinner } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import router from '@/router';
import useUsers from '@/views/users/useUsers';
import UserEditTabAccount from './UserEditTabAccount.vue';
import UserEditTabInformation from './UserEditTabInformation.vue';
import UserEditTabSocial from './UserEditTabSocial.vue';

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BSpinner,

        UserEditTabAccount,
        UserEditTabInformation,
        UserEditTabSocial,
    },

    computed: {
        isErrorOccured() {
            return this.userData === null && !this.isLoading;
        },

        isUserDataFetched() {
            return this.userData && !this.isLoading;
        },
    },

    methods: {
        handleReset() {
            const { id } = this.$route.params;

            this.isLoading = true;
            this.userData = null;

            this.getUserByID(id);
        },
    },

    setup() {
        const isLoading = ref(true);
        const userData = ref({});
        const { fetchUser } = useUsers();

        const getUserByID = (id) => {
            return fetchUser(id)
                .then((response) => {
                    userData.value = response.data;
                })
                .catch(() => {
                    userData.value = null;
                })
                .finally(() => {
                    isLoading.value = false;
                });
        };

        getUserByID(router.currentRoute.params.id);

        return {
            // Reactive data
            isLoading,

            // Data
            userData,

            // Methods
            getUserByID,
        };
    },
};
</script>

<style>
.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
