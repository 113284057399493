import { ref, reactive, toRefs, watch, computed } from '@vue/composition-api';
import store from '@/store';
// import { title } from '@core/utils/filter';
import getSearchParams from '@/utils/getSearchParams';
import { subscriptionBadgeText } from '@/utils/formatter';

// Notification
import useHandleError from '@/utils/useHandleError';

// Localization
import i18n from '@/libs/i18n/index';

export default function useUsers() {
    const { showErrorMessage } = useHandleError();

    const refUserListTable = ref(null);

    const i18nReactive = reactive(i18n);

    const getLocalizedLabels = () => {
        return [
            {
                key: 'email',
                label: i18nReactive.tc('ui.email'),
                sortable: true,
            },
            {
                key: 'role',
                label: i18nReactive.tc('user.role'),
                sortable: true,
            },
            {
                key: 'strategy',
                label: i18nReactive.tc('user.strategy'),
                formatter: (strategy) => strategy || '-',
                sortable: true,
            },
            {
                key: 'subscription',
                label: i18nReactive.tc('user.subscription'),
                formatter: subscriptionBadgeText,
                sortable: true,
            },
            {
                key: 'actions',
                label: i18nReactive.tc('ui.actions'),
            },
        ];
    };

    // Table Handlers
    const tableColumns = ref(getLocalizedLabels());
    const totalUsers = ref(0);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const lastPage = ref(1);

    const searchParams = reactive({
        perPage: 10,
        currentPage: 1,
        searchQuery: '',
        roleFilter: null,
        strategyFilter: null,
        subscriptionFilter: null,
    });

    const parseSearchParams = () => {
        const windowData = Object.fromEntries(
            new URL(window.location).searchParams.entries()
        );

        const VALID_KEYS = Object.keys(searchParams);

        VALID_KEYS.forEach((key) => {
            if (!windowData[key]) return;
            if (key === 'searchQuery') {
                searchParams[key] = windowData[key];
                return;
            }

            searchParams[key] = Number.parseInt(windowData[key], 10) || null;
        });
    };

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;

        return {
            from:
                searchParams.perPage * (searchParams.currentPage - 1) +
                (localItemsCount ? 1 : 0),
            to:
                searchParams.perPage * (searchParams.currentPage - 1) +
                localItemsCount,
            of: totalUsers.value,
        };
    });

    const refetchUsersList = () => {
        refUserListTable.value.refresh();
    };

    const refetchTableColumnLables = () => {
        tableColumns.value = getLocalizedLabels();
    };

    const pushSearchParamsToHistory = () => {
        const urlSearchParams = getSearchParams(searchParams).toString();

        window.history.pushState(
            null,
            document.title,
            `${window.location.pathname}?${urlSearchParams}`
        );
    };

    parseSearchParams();

    watch(
        [
            () => searchParams.perPage,
            () => searchParams.searchQuery,
            () => searchParams.roleFilter,
            () => searchParams.strategyFilter,
            () => searchParams.subscriptionFilter,
        ],
        () => {
            searchParams.currentPage = 1;
            refetchUsersList();
            pushSearchParamsToHistory();
        }
    );

    watch(
        () => searchParams.currentPage,
        () => {
            refetchUsersList();
            pushSearchParamsToHistory();
        }
    );

    watch(
        () => i18nReactive.locale,
        () => refetchTableColumnLables()
    );

    const fetchUsers = (ctx, callback) => {
        store
            .dispatch('app-users/fetchUsers', {
                locale: i18nReactive.locale,
                params: {
                    per_page: searchParams.perPage,
                    page: searchParams.currentPage,
                    role_id: searchParams.roleFilter,
                    strategy_id: searchParams.strategyFilter,
                    subscription_id: searchParams.subscriptionFilter,
                    email:
                        searchParams.searchQuery !== ''
                            ? searchParams.searchQuery
                            : null,
                },
            })
            .then((response) => {
                const {
                    items = [],
                    total = 0,
                    lastPage: numberOfPages = 1,
                } = response.data;

                callback(items);
                totalUsers.value = total;
                lastPage.value = numberOfPages;
            })
            .catch(showErrorMessage);
    };

    /**
     * Make API call to get information about user
     * @param {Number} id - User id
     * @returns {Promise} Promise with resolved data, or error object
     */
    const fetchUser = (id) => {
        return store
            .dispatch('app-users/fetchUser', {
                locale: i18nReactive.locale,
                id,
            })
            .catch(showErrorMessage);
    };

    const cancelAutoRenewalByUserID = (id) => {
        return store
            .dispatch('app-users/cancelAutoRenewalByUserID', {
                locale: i18nReactive.locale,
                id,
            })
            .catch(showErrorMessage);
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserSubscriptionVariant = (subscription) => {
        if (subscription === 'All Active') return 'success';
        if (subscription === 'Subscription is Active') return 'info';
        if (subscription === 'Auto-Renewal is Active') return 'warning';
        return 'secondary';
    };

    return {
        tableColumns,
        totalUsers,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        lastPage,

        fetchUsers,
        fetchUser,
        cancelAutoRenewalByUserID,
        resolveUserSubscriptionVariant,
        refetchUsersList,

        // Extra Filters
        ...toRefs(searchParams),
    };
}
