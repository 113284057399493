import store from '@/store';
import useHandleError from '@/utils/useHandleError';
import { ref } from '@vue/composition-api';

export default function useStrategies() {
    const { showErrorMessage } = useHandleError();

    const strategiesData = ref([]);

    const fetchStrategies = () => {
        return store
            .dispatch('app-strategies/fetchStrategies')
            .then((response) => {
                const { strategy } = response.data;

                strategiesData.value = strategy;
            })
            .catch(showErrorMessage);
    };

    const getStrategyName = (strategy) => {
        if (
            strategiesData.value.length === 0 ||
            strategy === undefined ||
            strategy === null
        )
            return '';

        const matchedStrategy = strategiesData.value.find(
            (item) => item.id === strategy.strategy_id
        );

        return matchedStrategy.name;
    };

    const getStrategyActionName = (actionId) => {
        switch (actionId) {
            case 1:
                return 'payment';
            case 2:
                return 'authorization';
            case 3:
                return 'issueASubscription';
            default:
                return 'none';
        }
    };

    return {
        // Reactive data
        strategiesData,

        // Methods
        fetchStrategies,
        getStrategyName,
        getStrategyActionName,
    };
}
