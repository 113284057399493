<template>
    <div>
        <!-- Media -->
        <b-media class="mb-2">
            <template #aside>
                <b-avatar
                    ref="previewEl"
                    :src="userData.avatar"
                    :text="avatarText(userData.email)"
                    :variant="`light-${theme}`"
                    size="90px"
                    rounded
                />
            </template>

            <h4 class="mb-1">
                {{ userData.username }}
            </h4>

            <!-- Edit user's avatar buttons -->
            <!-- <div class="d-flex flex-wrap">
                <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @input="inputImageRenderer"
                    />

                    <span class="d-none d-sm-inline">Update</span>

                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                </b-button>

                <b-button variant="outline-secondary" class="ml-1">
                    <span class="d-none d-sm-inline">Remove</span>

                    <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                </b-button>
            </div> -->
        </b-media>

        <!-- User Info: Input Fields -->
        <b-form>
            <b-row>
                <!-- Field: Username -->
                <b-col cols="12" lg="3">
                    <b-form-group
                        :label="$t('ui.username')"
                        label-for="username"
                    >
                        <b-form-input
                            id="username"
                            v-model="userData.username"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Email -->
                <b-col cols="12" lg="3">
                    <b-form-group :label="$t('ui.email')" label-for="email">
                        <b-form-input
                            id="email"
                            v-model="userData.email"
                            type="email"
                            required
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: strategy -->
                <b-col cols="12" lg="3">
                    <b-form-group
                        :label="$t('user.strategy')"
                        label-for="user-strategy"
                    >
                        <v-select
                            v-model="userData.queue.strategy.id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="strategiesOptions"
                            class="w-100"
                            label="name"
                            required
                            :reduce="(option) => option.id"
                            :searchable="false"
                            :clearable="false"
                            input-id="user-strategy"
                        />
                    </b-form-group>
                </b-col>

                <!-- Field: Role -->
                <b-col cols="12" lg="3">
                    <b-form-group
                        :label="$t('user.role')"
                        label-for="user-role"
                    >
                        <v-select
                            v-model="userData.role.id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="roleOptions"
                            class="w-100"
                            label="name"
                            required
                            :reduce="(option) => option.id"
                            :searchable="false"
                            :clearable="false"
                            input-id="user-role"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>

        <div class="d-flex flex-column-reverse flex-sm-row mt-2">
            <b-button
                variant="primary"
                class="mt-4 m-sm-0"
                type="reset"
                :to="{
                    name: 'users-view',
                    params: { id: userID },
                }"
            >
                {{ $t('ui.back') }}
            </b-button>

            <!-- Action Buttons -->
            <b-button
                variant="primary"
                class="m-0 mt-1 mt-sm-0 ml-sm-auto mr-sm-1"
            >
                {{ $t('ui.save') }}
            </b-button>

            <b-button
                variant="outline-secondary"
                type="reset"
                @click="$emit('reset', $event)"
            >
                {{ $t('ui.reset') }}
            </b-button>
        </div>
    </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { avatarText } from '@/utils/formatter';
import useUsers from '../useUsers';
import useStrategies from '../../strategies/useStrategies';

export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        vSelect,
    },

    props: {
        userData: {
            type: Object,
            required: true,
        },
    },

    computed: {
        theme() {
            const { role: { theme = 'secondary' } = {} } = this.userData || {};

            return theme;
        },

        userID() {
            const { id = this.$route.params.id } = this.userData || {};

            return id;
        },

        strategiesOptions() {
            return this.$store.getters['app-strategies/GET_STRATEGIES'] || [];
        },

        roleOptions() {
            return this.$store.getters['app-roles/GET_ROLES'] || [];
        },
    },

    setup(props) {
        const { getStrategyName } = useStrategies();
        const { resolveUserRoleVariant } = useUsers({ getStrategyName });

        const strategyOptions = [
            { label: 'Pending', value: 'pending' },
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];

        const permissionsData = [
            {
                module: 'Admin',
                read: true,
                write: false,
                create: false,
                delete: false,
            },
            {
                module: 'Staff',
                read: false,
                write: true,
                create: false,
                delete: false,
            },
            {
                module: 'Author',
                read: true,
                write: false,
                create: true,
                delete: false,
            },
            {
                module: 'Contributor',
                read: false,
                write: false,
                create: false,
                delete: false,
            },
            {
                module: 'User',
                read: false,
                write: false,
                create: false,
                delete: true,
            },
        ];

        // ? Demo Purpose => Update image on click of update
        const refInputEl = ref(null);
        const previewEl = ref(null);

        const { inputImageRenderer } = useInputImageRenderer(
            refInputEl,
            (base64) => {
                // eslint-disable-next-line no-param-reassign
                props.userData.avatar = base64;
            }
        );

        return {
            resolveUserRoleVariant,
            avatarText,
            strategyOptions,
            permissionsData,

            //  ? Demo - Update Image on click of update button
            refInputEl,
            previewEl,
            inputImageRenderer,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
